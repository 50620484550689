// Seção
.home-blog {
  //background: $cor_secundaria;
}

// Bloco post destaque
.bloco-post-dest {
  position: relative; 
  height: 430px;
  background: #FFF;
  margin-top: 5px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  // TABLET
  @include mq(m) {
    height: 340px;
  }
  
  // MOBILE
  @include mq(s) {
    height: auto;
  }
  
  // Foto
  figure {
    overflow: hidden;
    
    img {
      transition: .3s ease-out;
    }
  }
  &:hover figure img {
    transform: scale(1.1);
  }
  
  // Descrição
  > div {
    padding: 20px;
    background: #FFF;
    
    // TABLET
    @include mq(m) {
      padding: 10px;
    }
    
    // MOBILE
    @include mq(s) {
      padding: 10px;
    }
  }
  
  // Título
  h3 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    color: $cor_fonte_escura;
    margin-bottom: 10px;
    transition: .3s ease-out;
    
    // TABLET
    @include mq(m) {
      font-size: 16px;   
    }

    // MOBILE
    @include mq(s) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  &:hover h3 {
    color: $cor_primaria;
  }
  
  // Texto
  h4 {
    color: $cor_fonte_clara;
    font-size: 16px;
    font-weight: 300;
    transition: .3s ease-out;
    
    // TABLET
    @include mq(m) {
      font-size: 14px;      
    }
  }
  &:hover h4 {
    color: $cor_fonte_escura;
  }
}
