// Bloco serviço
.servico {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 20px;
  
  // Foto
  figure img {
    transform: scale(1.2);
    transition: .5s ease-out;
  }
  &:hover figure img {
    transform: scale(1);
  }
  
  // Máscara
  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .3);
    transition: .3s ease-out;
    
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &:hover span {
    background: rgba(#000, .6);
  }
  
  // Título
  h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(#000, .3);
    margin-top: 40px;
    margin-bottom: 15px;
    
    // MOBILE
    @include mq(s) {
      margin-top: 0px;
      font-size: 18px;
    }
  }
  
  // Botão
  .btn {
    opacity: 0;
    transform: scale(.5);
    
    // MOBILE
    @include mq(s) {
      opacity: 1;
      transform: scale(1);
    }
  }
  &:hover .btn {
    opacity: 1;
    transform: scale(1);
  }
}

// Carrosel
.carrosel-servicos {
  //margin-top: px;

  // Bloco serviço
  .servico {
    display: block;
    width: 100%;
    margin: 5px 10px;
  }
}
